import * as React from "react"
import * as PropTypes from "prop-types"
import { graphql } from "gatsby"
import BlogItem from "../components/BlogItem"
import Banner from "../components/Banner"
import Layout from "../components/Layout"

const Tags = ({ pageContext, data }) => {
	const { tag } = pageContext
	const { edges, totalCount } = data.allMarkdownRemark
	const tagHeader = `${totalCount} post${
		totalCount === 1 ? "" : "s"
	} tagged with "${tag}"`

	return (
		<>
			<Layout>
				<Banner content={tagHeader} />

				{edges.map(({ node }, index) => {
					return <BlogItem nodeObj={node} index={index} />
				})}
			</Layout>
		</>
	)
}

Tags.propTypes = {
	pageContext: PropTypes.shape({
		tag: PropTypes.string.isRequired,
	}),
	data: PropTypes.shape({
		allMarkdownRemark: PropTypes.shape({
			totalCount: PropTypes.number.isRequired,
			edges: PropTypes.arrayOf(
				PropTypes.shape({
					node: PropTypes.shape({
						frontmatter: PropTypes.shape({
							title: PropTypes.string.isRequired,
						}),
						// fields: PropTypes.shape({
						//   slug: PropTypes.string.isRequired,
						// }),
					}),
				}).isRequired,
			),
		}),
	}),
}

export default Tags

export const pageQuery = graphql`
	query ($tag: String) {
		allMarkdownRemark(
			limit: 2000
			sort: { fields: [frontmatter___date], order: DESC }
			filter: { frontmatter: { tags: { in: [$tag] } } }
		) {
			totalCount
			edges {
				node {
					id
					frontmatter {
						title
						date(formatString: "MMMM DD, YY")
						slug
						tags
						featuredImage {
							childImageSharp {
								gatsbyImageData(layout: FULL_WIDTH)
							}
						}
					}
					excerpt
				}
			}
		}
	}
`
